<template>
  <v-container>
    <h1 class="mb-3" :class="isOnPC ? 'text-h3' : 'text-h4'">Pengakuan Iman Gereja</h1>
    <ul class="text-justify">
      <li>Kami percaya kepada Allah Bapa, Anak dan Roh Kudus, ketiganya yang Esa, pencipta semesta alam.</li>
      <li>Kami percaya kepada Tuhan Yesus Anak Allah yang tunggal yang dikandung dari Roh Kudus menjadi manusia sejati, mati, bangkit naik kesorga duduk disebelah kanan Tahta Bapa di sorga. Menjalankan tugas sebagai penguasa, Iman Besar Agung, Penolong dan akan datang pada kali yang kedua untuk melantik saleh-salehNya di udara serta menjadi Raja dan Hakim yang adil.</li>
      <li>Kami percaya akan Roh Kudus, sebagai pemimpin, penolong, pemberi jasa dan karnia rohani.</li>
      <li>Kami percaya bahwa Perjanjian Lama dan Perjanjian Baru adalah Firman Allah diilhamkan oleh Roh Kudus sebagai pedoman keselamatan dalam kehidupan sehari-hari.</li>
      <li>Kami percaya Gereja Kudus yang AM dan Tuhan Yesus Kristus sebagai kepala Gereja serta adanya Baptisan Selam dan Perjamuan Kudus.</li>
      <li>Kami Percaya ada Sorga dan Neraka.</li>
      <li>Kami percaya akan kebangkitan orang mati dan kehidupan yang kekal.</li>
    </ul>
  </v-container>
</template>

<script>
export default {
  name: 'CreedView',

  computed: {
    isOnPC () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  }
}
</script>
